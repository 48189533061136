<template>
  <div class="page" v-if="page" :loaded="Boolean(page)">
    <section class="top-section">
      <div class="wrapper">
        <div class="container">
          <div class="content">
            <div class="title">Incubation</div>
            <h3>{{page.title}}</h3>
            <h5>{{page.tagline}}</h5>
            <p>{{page.description}}</p>
          </div>
          <div class="section-image">
            <img :src="page.image" alt="">
          </div>
        </div>
      </div>
    </section>

    <section class="incubation" v-if="incubators && incubators.length">
      <div class="wrapper">
        <div class="container">
          <div class="incubator" v-for="incubator in incubators" :key="incubator.id">
            <router-link :to="`/incubation/${incubator.seo.slug}`">
              <img :src="incubator.image" :alt="incubator.name" class="incubator-image">
            </router-link>
            <h5 :style="{ color: incubator.color }">{{incubator.name}}</h5>
            <p>{{incubator.excerpt}}</p>
            <div class="buttons">
              <router-link class="btn ghost" :style="{ color: incubator.color }" :to="`/incubation/${incubator.seo.slug}`">
                <span>Read more</span>
                <Icon type="fas" icon="arrow-right" />
              </router-link>
              <a v-if="incubator.email" class="btn ghost" :style="{ color: incubator.color }" :href="`mailto:${incubator.email}`">
                <span>Contact</span>
              </a>
              <a v-if="incubator.website" class="btn ghost icon" target="_blank" :style="{ color: incubator.color }" :href="incubator.website">
                <Icon type="fas" icon="globe" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Icon from "./Icon.vue";
import { mapGetters } from "vuex";

export default {
  name: "Incubation",
  components: {
    Icon,
  },
  data() {
    return {
      page: null,
      incubators: [],
    };
  },
  computed: {
    ...mapGetters({
      settings: "getSettings",
      isMobile: "getMobile",
      testMode: "getTest",
    }),
    db() {
      return this.$store.state.firebase.firestore;
    },
  },
  methods: {
    getPage() {
      if (this.pageRef) this.pageRef();
      if (this.incubationRef) this.incubationRef();
      this.pageRef = this.db()
        .collection(`${this.testMode ? "test_" : ""}pages`)
        .doc("incubation")
        .onSnapshot((page) => {
          this.page = {
            title: "",
            description: "",
            image: "",
            ...page.data(),
          };
        });
      this.incubationRef = this.db()
        .collection(`${this.testMode ? "test_" : ""}incubations`)
        .orderBy("order")
        .onSnapshot((incubators) => {
          this.incubators = incubators.docs.map((incubator) => incubator.data());
        });
    },
  },
  watch: {
    testMode() {
      this.getPage();
    },
  },
  mounted: function() {
    this.getPage();
  },
  beforeDestroy: function() {
    if (this.pageRef) this.pageRef();
    if (this.incubationRef) this.incubationRef();
  },
};
</script>

<style scoped>
.top-section {
  position: relative;
  margin-bottom: 64px;
}

.top-section .container {
  position: relative;
  display: flex;
  align-items: center;
  background: rgba(28, 172, 214, 0.08) url(../assets/images/wb_o_incubation.svg) no-repeat 16% top/52% auto;
  border-radius: 16px;
  min-height: 568px;
  padding: 48px 0;
  margin-right: 144px;
}

.top-section .container .content {
  position: relative;
  width: calc(50% + 72px);
  padding: 0 42px 0 96px;
  min-width: 420px;
  z-index: 2;
}

.top-section .container .content h3 {
  font-size: 72px;
}

.top-section .container .content h5 {
  margin: 16px 0 32px;
  line-height: 1.2;
  color: #656662;
}

.top-section .container .content p {
  font-size: 18px;
}

.top-section .content .buttons {
  margin-top: 64px;
}

.top-section .section-image {
  position: absolute;
  top: 24px;
  right: -144px;
  width: calc(50% + 72px);
  bottom: 24px;
  border-radius: 16px;
  background-color: #fafafa;
  overflow: hidden;
}

.top-section .content .title {
  display: flex;
  align-items: center;
  color: #1dacd6;
  font-size: 20px;
  margin-bottom: 24px;
}

.top-section .content .title:before {
  content: '';
  display: block;
  width: 112px;
  height: 21px;
  background: url(../assets/images/wb_o_4dots.svg) no-repeat center/contain;
  margin-right: 24px;
}

.top-section .section-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 16px;
}

.incubation .container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: 0 -24px;
}

.incubation .incubator {
  width: 50%;
  padding: 0 24px;
  margin-bottom: 96px;
}

.incubation .incubator .incubator-image {
  display: block;
  width: 100%;
  height: 420px;
  object-fit: cover;
  border-radius: 16px;
  margin-bottom: 32px;
}

.incubation .incubator p {
  margin: 24px 0 40px;
  min-height: 104px;
}

.incubation .incubator .buttons .btn {
  margin-bottom: 16px;
}

#app[no-touch] .incubation .incubator .btn:hover {
  background: currentColor;
}

#app[no-touch] .incubation .incubator .btn:hover span,
#app[no-touch] .incubation .incubator .btn:hover .icon {
  color: #fff;
}

@media screen and (max-width: 1520px), screen and (max-height: 820px) {
  .incubation .incubator .incubator-image {
    height: 360px;
  }
}

@media screen and (max-width: 1280px) {
  .top-section .container .content {
    padding-left: 64px;
  }
}

@media screen and (max-width: 1024px) {
  .top-section:before {
    left: 0;
    right: 0;
    margin: 32px auto 0;
  }
  .top-section .section-image {
    display: none;
  }
  .top-section .container {
    min-height: auto;
    margin-right: 0;
  }
  .top-section .container .content {
    width: 100%;
    min-width: 0;
  }
}

@media screen and (max-width: 880px) {
  .incubation .incubator {
    padding: 0 12px;
  }
  .incubation .incubator .incubator-image {
    height: 280px;
  }
  .top-section .container .content {
    margin: 0;
    padding: 48px;
    max-width: none;
  }
}

@media screen and (max-width: 730px) {
  .incubation .incubator {
    width: 100%;
    padding: 0;
    margin-bottom: 48px;
  }
}

@media screen and (max-width: 480px) {
  .top-section .container .content {
    padding: 32px;
  }
}
</style>
